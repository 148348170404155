import * as actions from './actions';

export type TimelineWithImagesState = {
  index: number | null;
};

export const initialState: TimelineWithImagesState = {
  index: null,
};

const TimelineWithImages = (
  state: TimelineWithImagesState = initialState,
  action: actions.TimelineWithImagesAction,
): TimelineWithImagesState => {
  switch (action.type) {
    case actions.SET_INDEX_TIMELINE_W_IMG:
      return {
        ...state,
        index: (action as actions.SetTimelineIndex).payload,
      };
    default:
      return state;
  }
};

export default TimelineWithImages;
