export const SET_INDEX_TIMELINE_W_IMG = '[timeline-w-img] index/set';

export type SetTimelineIndex = {
  type: typeof SET_INDEX_TIMELINE_W_IMG;
  payload: number | null;
};

export const setTimelineIndex = (
  index: number,
): SetTimelineIndex => ({
  type: SET_INDEX_TIMELINE_W_IMG,
  payload: index,
});

export type TimelineWithImagesAction =
  | SetTimelineIndex;
